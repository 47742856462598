@import "variables.scss";

body {
  font-family: "Fira Code", sans-serif;
}

h1 {
  font-family: "Lighters", sans-serif !important;
  font-weight: normal !important;
  color: $blue;
}
.handwritten {
  font-family: "Windsong", cursive !important;
}

// ************************ CV

@media print {
  .noprint,
  #footer,
  #header,
  #nav {
    display: none !important;
  }
  .imgLynne,
  .imgHello {
    width: 260px !important;
  }
}
