$blue: "#8AA6BF";
$dark: "#6D7E8C"; 
$light: "#BACBD9";

$white: "#F0F0F2";
$gray: "#595652";
$black: "#0D0D0D";

$pink: "#BF9F9F";
$yellow: "#BF9F78";
$green: "#A3A65D";

// Google Fonts import
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400&family=WindSong:wght@500&display=swap');

// Creative Market fonts
      @font-face {
          font-family: 'Lighters';
          src: local('Lighters'), url(./fonts/Lighters-Regular.otf) format('opentype');
          /* other formats include: 'woff2', 'truetype, 'opentype',
                                    'embedded-opentype', and 'svg' */
        }